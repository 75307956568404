// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-tsx": () => import("/home/patryk/Projects/kalinowski.dev/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resume-tsx": () => import("/home/patryk/Projects/kalinowski.dev/src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/patryk/Projects/kalinowski.dev/.cache/data.json")

