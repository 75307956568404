module.exports = [{
      plugin: require('/home/patryk/Projects/kalinowski.dev/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/patryk/Projects/kalinowski.dev/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Fira Code:400,700"]}},
    },{
      plugin: require('/home/patryk/Projects/kalinowski.dev/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
